<template>
  <div class="sub-cont">
    <div class="mypage-wrap" style="padding-bottom: 0">
      <h2 class="h2-mypage">학력</h2>
      <button class="btn __add" @click="addForm">
        <i class="el-icon-plus"></i> 추가
      </button>
      <div class="empty_form" v-if="isEducationEmpty">
        학력사항을 추가하세요.
      </div>
      <div
        v-for="(education, ix) in educations"
        :key="`ca_${ix}`"
        class="input-form-wrap"
      >
        <div class="input-form">
          <div class="t-head">분류</div>
          <div class="t-doc">
            <select
              v-model="education.schoolGradCode"
              name=""
              id=""
              class="select"
              :ref="`schoolGradCode_${ix}`"
            >
              <option value="">분류</option>
              <option
                v-for="(schoolGrad, idx) in schoolGradCodeList"
                :key="`bt_${idx}`"
                :value="schoolGrad.code"
                :selected="schoolGrad.code == education.schoolGradCode"
              >
                {{ schoolGrad.codeValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">상태</div>
          <div class="t-doc">
            <select
              v-model="education.gradTypeCode"
              name=""
              id=""
              class="select"
              :ref="`gradTypeCode_${ix}`"
            >
              <option value="">상태</option>
              <option
                v-for="(grad, idx) in gradTypeCodeList"
                :key="`bt_${idx}`"
                :value="grad.code"
                :selected="grad.code == education.gradTypeCode"
              >
                {{ grad.codeValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">학교명</div>
          <div class="t-doc">
            <input
              v-model="education.schoolName"
              type="text"
              class="text"
              :ref="`schoolName_${ix}`"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">전공</div>
          <div class="t-doc">
            <input
              v-model="education.major"
              type="text"
              class="text"
              :ref="`major_${ix}`"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">입학일</div>
          <input
            type="date"
            v-model="education.entryDate"
            class="inp datepicker"
            :id="`entryDate_${ix}`"
            autocomplete="off"
            inputmode="none"
            :ref="`entryDate_${ix}`"
          />
        </div>
        <div class="input-form">
          <div class="t-head">졸업일</div>
          <input
            type="date"
            v-model="education.gradDate"
            class="inp datepicker"
            :id="`gradDate_${ix}`"
            autocomplete="off"
            inputmode="none"
            :ref="`gradDate_${ix}`"
          />
        </div>
        <div class="btn_box">
          <button class="btn __delete" @click="deleteCareer(ix)">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEduCodeList,
  getEduStatusCodeList,
  getEduList,
  saveEduInfo,
} from 'Api/modules'
import resumeMixin from 'Mixins/resume'
import datePickerMixin from 'Mixins/datePicker'

export default {
  mixins: [resumeMixin, datePickerMixin],
  data() {
    return {
      userId: '',
      schoolGradCodeList: [],
      gradTypeCodeList: [],
      educations: [
        {
          schoolGradCode: '',
          gradTypeCode: '',
          schoolName: '',
          major: '',
          entryDate: '',
          gradDate: '',
        },
      ],
    }
  },
  computed: {
    isEducationEmpty() {
      return this.educations.length == 0
    },
  },
  methods: {
    async save() {
      console.info('educations save', this.educations)
      //입력 오류
      if (!this.checkValues(this.educations)) return false

      try {
        // const res = await saveEduInfo({ educations: this.educations })
        await saveEduInfo({ educations: this.educations })
        this.$toasted.show('학력사항이 등록되었습니다.')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    deleteCareer(idx) {
      this.deleteItemOrClearForm(this.educations, idx)
    },
    addForm() {
      this.educations.push({
        schoolGradCode: '',
        gradTypeCode: '',
        schoolName: '',
        major: '',
        entryDate: '',
        gradDate: '',
      })
    },
  },
  async created() {
    //학력 분류 목록 조회
    this.schoolGradCodeList = await getEduCodeList()
    //학력 상태 목록 조회
    this.gradTypeCodeList = await getEduStatusCodeList()

    // 학력 목록 조회
    try {
      const res = await getEduList({ userId: this.account.userId })
      if (res && res.length > 0) {
        this.educations = res
      }
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
}
</script>

<style>
.empty_form {
  padding-top: 90px;
  height: 160px;
  border: 1px;
  border-style: none none solid none;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
</style>
